export var numerals = [
  [0, 'efes', 'אֶפֶס'],
  [1, 'achat', 'אַחַת', 'echad', 'אֶחָד'],
  [2, 'shtayim', 'שְׁתַּיִם', 'shnayim', 'שְׁנַיִם'],
  [3, 'shalosh', 'שָׁלֹשׁ', 'shlosha', 'שְׁלֹשָׁה'],
  [4, 'arba\'', 'אַרְבַּע', 'arba\'a', 'אַרְבָּעָה'],
  [5, 'chamesh', 'חָמֵשׁ', 'chamisha', 'חֲמִשָּׁה'],
  [6, 'shesh', 'שֵׁשׁ', 'shisha', 'שִׁשָּׁה'],
  [7, 'sheva\'', 'שֶׁבַע', 'shiv\'a', 'שִׁבְעָה'],
  [8, 'shmone', 'שְׁמוֹנֶה', 'shmona', 'שְׁמוֹנָה'],
  [9, 'tesha\'', 'תֵּשַׁע', 'tish\'a', 'תִּשְׁעָה'],
  [10, '\'eser', 'עֶשֶׂר', '\'assara', 'עֲשָׂרָה'],
];
